export const structure = {
  map: {},
  tree: [],
  fields: {},
  layerNames: {},
  layerTypes: {},
  selectedModelId: null,
  activeLayers: [],
  objectsCount: {}
}

export const nonClearableFields = ['city', 'map', 'activeLayers']
