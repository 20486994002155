import { cloneDeep, isArraysEqual } from 'HELPERS'

const isObject = obj =>
  Object.prototype.toString.call(obj) === '[object Object]'

export const compareSource = ({
  source,
  initialSource,
  excludeFields = [],
  possibleDifferentValues,
  excludeNullish
}) => {
  if (!source && !initialSource) return false

  const cloneSource = cloneDeep(source)

  for (const key in cloneSource) {
    if (!excludeFields.includes(key)) {
      const sourceItem = cloneSource[key]
      const cloneSourceItem = initialSource?.[key]

      // Исключение nullish значений
      if (excludeNullish && sourceItem === null) {
        delete cloneSource[key]
      }
      // Исключение значений из possibleDifferentValues
      else if (
        possibleDifferentValues &&
        (possibleDifferentValues.includes(sourceItem) ||
          (Array.isArray(sourceItem) &&
            possibleDifferentValues.includes(sourceItem[0])))
      ) {
        delete cloneSource[key]
      }
      // Обработка массивов
      else if (Array.isArray(sourceItem)) {
        const isEqual = isArraysEqual(sourceItem, cloneSourceItem)

        if (isEqual) {
          delete cloneSource[key]
        } else {
          cloneSource[key] = sourceItem
        }
      }
      // Обработка объектов
      else if (isObject(sourceItem)) {
        cloneSource[key] = compareSource({
          source: sourceItem,
          initialSource: cloneSourceItem
        })

        if (!Object.keys(cloneSource[key]).length) {
          delete cloneSource[key]
        }
      } else {
        // Сравнение простых значений
        if (sourceItem === cloneSourceItem) {
          delete cloneSource[key]
        }
      }
    } else {
      delete cloneSource[key]
    }
  }

  return cloneSource
}
