var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"r-select",class:{
    disabled: _vm.disabled,
    error: _vm.error,
    'dropdown-style': _vm.dropdownStyle,
    'show-all': _vm.showAllSelectedItems,
    opened: _vm.isMenuOpened
  }},[(_vm.label)?_c('r-text',{attrs:{"color-type":"subhead"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading && !_vm.filteredOptions.length),expression:"loading && !filteredOptions.length"}],ref:"wrapper",staticClass:"r-select__input-wrapper",class:{ angular: _vm.angular },style:({ maxWidth: _vm.maxWidth, width: `${_vm.width}px` }),attrs:{"tabindex":"0"},on:{"click":_vm.inputClickHandler,"mouseenter":function($event){_vm.isClearButtonShowing = true},"mouseleave":function($event){_vm.isClearButtonShowing = false}}},[(!_vm.hasNoTextData)?_c('div',{class:[
        'r-select__selected-items',
        { 'r-select__selected-items--no-crop': !_vm.cropMultipleSelected }
      ]},[_vm._l((_vm.showedItems),function(id,i){return _c('r-text',{key:i,staticClass:"r-select__selected-item",attrs:{"size":12}},[_c('span',{staticClass:"r-select__selected-item-text"},[_vm._v(" "+_vm._s(_vm.getOptionTitleById(id))+" ")]),(!_vm.getOptionDisabledById(id))?_c('r-icon',{staticClass:"r-select__selected-delete",attrs:{"name":"close-delete","size":15},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.deleteSelectedItem(id)}}}):_vm._e()],1)}),(_vm.multiple && _vm.active?.length > 2 && !_vm.showAllSelectedItems)?_c('div',{staticClass:"r-select__selected-item"},[_c('r-text',{staticClass:"r-select__selected-item-more",attrs:{"size":12,"color-type":"primary"}},[_vm._v(" "+_vm._s(`+${_vm.active?.length - 2}`)+" ")])],1):_vm._e(),(_vm.cacheLabel)?_c('r-text',{staticClass:"r-select__text-ellipsis"},[_vm._v(" "+_vm._s(_vm.cacheLabel)+" ")]):(_vm.placeholder && !_vm.active?.length)?_c('r-text',{staticClass:"r-select__text-ellipsis",attrs:{"color-type":"field-placeholder"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.placeholder))+" ")]):_vm._e()],2):(_vm.selectedOption)?_c('render-option',{attrs:{"dom":_vm.selectedOption.html[0].componentOptions.children}}):_vm._e(),(_vm.loading)?_c('r-button',{staticClass:"r-select__clear-button",attrs:{"loading":"","simple":"","mini":""}}):(_vm.isClearButton)?_c('r-button',{staticClass:"r-select__clear-button",attrs:{"simple":"","mini":"","icon":"clear-input"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.clearButtonClickHandler.apply(null, arguments)}}}):_c('r-button',{staticClass:"r-select__dropdown-icon",attrs:{"tabindex":"-1","icon":{ name: _vm.dropdownStyle ? '' : 'chevron-down', size: 16 },"dropdown":_vm.dropdownStyle,"mini":"","simple":"","disabled":_vm.disabled,"type":_vm.dropdownStyle ? 'primary' : 'default'},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggleMenu.apply(null, arguments)}}})],1),(
      (_vm.isMenuOpened && !_vm.loading) ||
      (_vm.isMenuOpened && (_vm.filteredOptions.length || _vm.text))
    )?_c('portal',{attrs:{"to":"main-portal"}},[_c('transition',{attrs:{"name":"unroll","mode":"in-out"}},[_c('select-menu',{key:_vm.menuTop,ref:"menu",attrs:{"loading":_vm.loading,"top":_vm.menuTop,"bottom":_vm.menuBottom,"left":_vm.menuLeft,"width":_vm.menuWidth,"filtered-options":_vm.filteredOptions,"multiple":_vm.multiple,"active":_vm.active,"mode":_vm.mode,"has-no-text-data":_vm.hasNoTextData,"no-select-all":_vm.noSelectAll,"service-options":!!_vm.serviceHandler,"cache-query":_vm.cacheQuery,"text":_vm.text,"disabled":_vm.disabled,"is-filterable":_vm.isFilterable,"total-count":_vm.totalCount,"cache-endpoint-options":_vm.cacheEndpointOptions,"cache-display-options":_vm.cacheDisplayOptions,"qty-page":_vm.currentPage,"can-be-empty":_vm.canBeEmpty},on:{"item-click":_vm.itemClickHandler,"select-all":_vm.selectAll,"filter-input":_vm.filterInputHandler,"show-more":_vm.showMoreHandler}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }