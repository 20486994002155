import $store from 'STORE'
import i18n from '@/extensions/i18n'

export const sendNotify = (
  type,
  id,
  { type: customType, message, title } = {}
) => {
  if ($store) {
    setImmediate(() => {
      switch (type) {
        case 'custom':
          $store.commit('SYSTEM_SET', [
            'notification',
            {
              type: customType || 'info',
              title: title || i18n.t('notification'),
              message: message || ''
            }
          ])

          break
        case 'delete':
        case 'save':
          $store.commit('SYSTEM_SET', [
            'notification',
            {
              type: 'success',
              title: i18n.t('notification'),
              message: id
                ? `${i18n.t('object')} №${id} ${i18n.t(`notify:${type}`)}`
                : `${i18n.t('object')} ${i18n.t(`notify:${type}`)}`,
              link: null
            }
          ])
          break
        case 'error':
          $store.commit('SYSTEM_SET', [
            'notification',
            {
              type: 'error',
              title: i18n.t('notification'),
              message: `${i18n.t(`notify:${type}`)}`
            }
          ])
          break
        case 'no-data':
          $store.commit('SYSTEM_SET', [
            'notification',
            {
              type: 'warning',
              title: i18n.t('notification'),
              message: i18n.t('no-data')
            }
          ])
          break
      }
    })
  } else {
    console.error('Store is not available')
  }
}
