import vue from 'vue'
import { structure, nonClearableFields } from './structure'
import { cloneDeep } from 'HELPERS'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getKsoddActiveLayers: state => state.activeLayers,
    getKsoddSelectedCity: state => state.city,
    getKsoddHashById: state => id => state.hash[id]
  },
  mutations: {
    KSODD_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    KSODD_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], child, value)
      } else {
        state[field] = value
      }
    },
    KSODD_PUSH(state, [field, value]) {
      state[field].push(value)
    },
    KSODD_SPLICE(state, [field, value]) {
      const index = state[field].indexOf(value)

      if (index >= 0) state[field].splice(index, 1)
    },
    KSODD_ASSIGN(state, { field, key, value }) {
      state[field] = Object.assign({ ...state[field] }, { [key]: value })
    },
    KSODD_MERGE(state, { field, object }) {
      state[field] = Object.assign({ ...state[field] }, object)
    }
  }
}
