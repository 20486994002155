<template>
  <div class="r-attributes">
    <dl class="r-attributes__list">
      <template v-for="item in data">
        <dt
          :key="item.title"
          class="r-attributes__term"
        >
          <r-text
            text-type="caption"
            color-type="secondary"
          >
            {{ $t(item.title) }}
          </r-text>
        </dt>
        <dd
          :key="`${item.title}-value`"
          class="r-attributes__definition"
        >
          <r-button
            v-if="item.datatype === 'jsonb'"
            class="r-attributes__json-btn"
            mini
            @click="$emit('open-json', item.value)"
          >
            {{ $t('expand') }}
          </r-button>
          <r-text
            v-else
            :color-type="getTextColorType(item)"
            style="white-space: pre-line"
          >
            {{ getValueByType(item.value, item.datatype) }}
          </r-text>
        </dd>
      </template>
    </dl>
  </div>
</template>

<script>
import { getValueByType } from 'HELPERS'
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    getTextColorType(item) {
      return item.type ? item.type : ''
    },
    getValueByType
  }
}
</script>

<style lang="scss">
.r-attributes {
  border-radius: $border-radius;
  width: 100%;
  overflow-y: auto;

  &__term {
    padding-top: 0.25rem;
  }

  &__definition {
    padding-bottom: 0.25rem;
    @include border-bottom;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__json-btn {
    margin-top: 0.25rem;
  }
}
</style>
