<template>
  <r-button
    class="r-delete-button"
    :mini="tiny"
    :class="{ cross, angular, transparent }"
    :disabled="disabled"
    type="danger"
    :title="label || $t('delete')"
    :rtl="rtl"
    :simple="!!cross"
    bold
    :icon="{
      name: cross ? 'close-delete' : icon ?? 'trash',
      size: tiny ? 18 : 22,
      color: 'var(--accent-danger)'
    }"
    @click="beforeDelete($event)"
  >
    <span
      v-if="(!mini && !tiny) || label"
      class="r-delete-button__text"
      :class="{ rtl }"
    >
      {{ label || $t('delete') }}
    </span>
  </r-button>
</template>

<script>
// import i18n from './extensions/i18n'

export default {
  props: {
    mini: {
      type: Boolean,
      default: false
    },
    tiny: {
      type: Boolean,
      default: false
    },
    rtl: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    cross: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    angular: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    transparent: {
      type: Boolean,
      default: false
    },
    dialogMessage: {
      type: String,
      default: null
    },
    dialogTitle: {
      type: String,
      default: null
    },
    deleteKey: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.deleteKey) {
      document.addEventListener('keydown', this.listener)
    }
  },
  beforeDestroy() {
    if (this.deleteKey) {
      document.removeEventListener('keydown', this.listener)
    }
  },
  methods: {
    listener(e) {
      if (!this.deleteKey) return

      const { shiftKey, code } = e

      if (shiftKey && code === 'Delete') {
        this.beforeDelete(e)
      }
    },
    async beforeDelete(event) {
      this.$emit('click', event)

      if (this.disabled) return

      if (this.simple) {
        this.$emit('delete', event)
      } else if (
        await this.$dialog({
          message: this.dialogMessage ?? this.$t('delete-message'),
          title: this.dialogTitle ?? this.$t('delete-object'),
          controls: {
            yes: {
              text: this.$t('delete'),
              icon: 'trash',
              type: 'danger'
            },
            no: {
              text: this.$t('no'),
              type: 'simple'
            }
          }
        })
      ) {
        this.$emit('delete', event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.r-delete-button {
  background: $accent-danger-hover;

  &.rtl {
    padding-right: 0.25rem;
  }

  &.angular {
    border-radius: 0;
  }

  &.cross,
  &.transparent {
    background-color: transparent;

    &:hover {
      background-color: transparent !important;
    }
  }

  &__text {
    color: $accent-danger !important;
  }
}
</style>

<i18n>
{
  "ru": {
    "r-delete:info": "Объект будет удалён безвозвратно"
  },
  "en": {
    "r-delete:info": "The object will be permanently deleted"
  }
}
</i18n>
