export default (beforeEnter, wrapper) => {
  return {
    name: 'monitoring',
    path: 'monitoring',
    beforeEnter,
    redirect: 'explorer',
    component: wrapper,
    meta: {
      title: 'monitoring',
      icon: 'camera',
      description: 'monitoring:desc'
    },
    children: [
      {
        name: 'monitoring-pt',
        path: 'monitoring-pt',
        beforeEnter,
        redirect: 'monitoring-pt/main',
        component: wrapper,
        meta: {
          title: 'monitoring-pt:title',
          abbr: 'monitoring-pt:abbr-title',
          icon: 'bus-hollow',
          description: 'monitoring-pt:module-description',
          endpoints: ['public_transport'],
          projects: ['rostov_tmp', 'demo', 'eputs', 'tagil', 'urfu']
        },
        children: [
          {
            name: 'pt-main',
            path: 'main',
            meta: {
              title: 'monitoring-pt:title',
              abbr: 'monitoring-pt:abbr-title',
              icon: 'bus-hollow',
              description: 'monitoring-pt:desc',
              isMap: true
            },
            beforeEnter,
            component: () =>
              import('@/modules/monitoring-pt/views/monitoring-pt')
          },
          {
            name: 'pt-dashboard',
            path: 'dashboard',
            redirect: 'monitoring-pt/dashboard/general',
            component: wrapper,
            meta: {
              title: 'monitoring-pt:title',
              abbr: 'monitoring-pt:abbr-title',
              subtitle: 'monitoring-pt:subtitle',
              icon: 's-center',
              description: 'monitoring-pt:desc'
            },
            children: [
              {
                name: 'pt-dashboard-general',
                path: 'general',
                meta: {
                  title: 'monitoring-pt:title',
                  abbr: 'monitoring-pt:abbr-title',
                  subtitle: 'monitoring-pt:subtitle',
                  icon: 'bus-hollow',
                  description: 'monitoring-pt:desc'
                },
                beforeEnter,
                component: () =>
                  import('@/modules/monitoring-pt/views/dashboard/general')
              },
              {
                name: 'pt-dashboard-report',
                path: 'report',
                meta: {
                  title: 'monitoring-pt:title',
                  abbr: 'monitoring-pt:abbr-title',
                  subtitle: 'monitoring-pt:subtitle',
                  icon: 'bus-hollow',
                  description: 'monitoring-pt:desc'
                },
                beforeEnter,
                component: () =>
                  import('@/modules/monitoring-pt/views/dashboard/report')
              }
            ]
          },
          {
            name: 'pt-schedule',
            path: 'schedule',
            meta: {
              title: 'monitoring-pt:title',
              subtitle: 'schedule',
              abbr: 'monitoring-pt:abbr-title',
              icon: 'bus-hollow',
              description: 'monitoring-pt:desc'
            },
            beforeEnter,
            component: () => import('@/modules/monitoring-pt/views/schedule')
          },
          {
            name: 'isochrone-analysis',
            path: 'isochrone-analysis',
            meta: {
              abbr: 'monitoring-pt:abbr-title',
              title: 'monitoring-pt:title',
              subtitle: 'monitoring-pt:isochrone-analysis',
              icon: 'node-layer',
              hiddenRoute: true,
              projects: ['rostov_tmp', 'demo', 'eputs'],
              endpoints: ['public_transport'],
              isMap: true
            },
            beforeEnter,
            component: () =>
              import('@/modules/monitoring-pt/views/isochrone-analysis/views')
          }
        ]
      },
      {
        name: 'parking',
        path: 'parking',
        beforeEnter,
        component: wrapper,
        redirect: 'parking/main',
        meta: {
          title: 'parking:title',
          abbr: 'parking:abbr-title',
          icon: 'parking-menu',
          description: 'parking:desc',
          endpoints: ['parking_space'],
          projects: ['demo', 'eputs']
        },
        children: [
          {
            name: 'parking-main',
            path: 'main',
            beforeEnter,
            component: () => import('@/modules/parking/views/parking'),
            meta: {
              abbr: 'parking:abbr-title',
              title: 'parking:title',
              icon: 'parking-menu',
              description: 'parking:desc',
              isMap: true
            }
          },
          {
            name: 'parking-registry',
            path: 'registry',
            beforeEnter,
            component: () => import('@/modules/parking/views/registry'),
            meta: {
              abbr: 'parking:abbr-title',
              title: 'parking:title',
              subtitle: 'registry',
              icon: 'parking-menu'
            }
          }
        ]
      },

      {
        name: 'its-control',
        path: 'its-control',
        beforeEnter,
        redirect: 'its-control/main',
        component: wrapper,
        meta: {
          title: 'its:title',
          abbr: 'its:abbr-title',
          icon: 's-center',
          description: 'its:desc',
          endpoints: ['efficiency_control'],
          projects: [
            'rostov_tmp',
            'rut_miit',
            'vladimir',
            'tashkent',
            'surgut',
            'tagil',
            'eputs',
            'demo',
            'urfu',
            'kdh',
            'mmk'
          ]
        },
        children: [
          {
            name: 'its-main',
            path: 'main',
            meta: {
              title: 'its:title',
              abbr: 'its:abbr-title',
              icon: 's-center',
              description: 'its:desc',
              isMap: true
            },
            beforeEnter,
            component: () => import('@/modules/its-control/views/main')
          },
          {
            name: 'its-dashboard',
            path: 'dashboard',
            meta: {
              title: 'its:title',
              abbr: 'its:abbr-title',
              subtitle: 'its:dashboard_title',
              icon: 's-center',
              description: 'its:desc',
              projects: [
                'rut_miit',
                'vladimir',
                'surgut',
                'tagil',
                'eputs',
                'demo',
                'urfu',
                'kdh',
                'mmk'
              ]
            },
            beforeEnter,
            component: () => import('@/modules/its-control/views/dashboard')
          },
          {
            name: 'its-development',
            path: 'development',
            meta: {
              title: 'its:title',
              abbr: 'its:abbr-title',
              subtitle: 'its:development',
              icon: 's-center',
              description: 'its:desc'
            },
            beforeEnter,
            component: () => import('@/modules/its-control/views/development')
          },
          {
            name: 'its-editor',
            path: 'editor',
            meta: {
              title: 'its:title',
              abbr: 'its:abbr-title',
              subtitle: 'its-editor',
              icon: 's-center',
              description: 'its:desc',
              projects: ['demo', 'eputs'],
              isMap: true
            },
            beforeEnter,
            component: () => import('@/modules/its-control/views/editor')
          }
        ]
      },
      {
        name: 'pdd',
        path: 'pdd',
        beforeEnter,
        component: wrapper,
        redirect: 'pdd/main',
        meta: {
          title: 'pdd:title',
          abbr: 'pdd:abbr-title',
          icon: 'camera',
          description: 'pdd:desc',
          endpoints: ['traffic_violations'],
          projects: ['demo', 'eputs', 'rostov_on_don', 'rostov_tmp']
        },
        children: [
          {
            name: 'pdd-main',
            path: 'main',
            beforeEnter,
            component: () => import('@/modules/pdd/views/pdd'),
            meta: {
              abbr: 'pdd:abbr-title',
              title: 'pdd:title',
              icon: 'camera',
              description: 'pdd:desc',
              isMap: true
            }
          },
          {
            name: 'pdd-registry',
            path: 'registry',
            beforeEnter,
            component: () => import('@/modules/pdd/views/registry'),
            meta: {
              abbr: 'pdd:abbr-title',
              title: 'pdd:title',
              subtitle: 'pdd:registry-desc',
              icon: 'camera',
              description: 'pdd:registry-desc'
            }
          }
        ]
      },
      {
        name: 'licenses',
        path: 'licenses',
        beforeEnter,
        component: () => import('@/modules/licenses/licenses'),
        meta: {
          title: 'permissions:title',
          abbr: 'permissions:abbr-title',
          icon: 'safety-driving',
          description: 'permissions:desc',
          endpoints: ['permissions'],
          projects: ['demo', 'eputs'],
          isMap: true
        }
      }
    ]
  }
}
