export const structure = {
  city: null,
  tree: {},
  map: {},
  objectsCount: {},
  activeLayers: [],
  fields: {},
  isSelectDisabled: false,
  hash: {},
  layerNames: {},
  hasUnsavedData: false,
  objectCard: null,
  sources: null
}

export const nonClearableFields = ['city', 'map', 'activeLayers']
