import vue from 'vue'
import { getCookie, deleteCookie } from 'HELPERS'
const DEV_MODE = process.env?.NODE_ENV === 'development'

export default {
  state: {
    token: getCookie('ritmToken'),
    user: null,
    permissions: {
      modules: null
    }
  },
  getters: {
    getToken: state => state.token,
    getCurrentUser: state => state.user,
    getModules: state => state.permissions.modules,
    hasUserAccessToModule: state => module_name => {
      return (
        !!state.permissions.modules?.get(module_name)?.available ||
        !!state.permissions.modules?.get(`${module_name}_v2`)?.available ||
        DEV_MODE
      )
    }
  },
  mutations: {
    USER_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], child, value)
      } else {
        state[field] = value
      }
    },
    CLEAR_USER_DATA(state) {
      state.token = undefined
      state.user = undefined
      deleteCookie('ritmToken')
    }
  }
}
