export const systemSettingsDefault = {
  locale: (navigator.language || navigator.languages[0])?.startsWith('ru')
    ? 'ru'
    : 'en',
  abbr: false,
  hideWsNotifications: false,
  extraGisLayers: {},
  customBaselayers: {},
  theme: 'system'
}

// true -> store[key]
// false -> controller[key]
export const storeMapModulesConfig = {
  activeLayers: true,
  zIndex: false,
  displayedAttributes: false
}

export const serverToFrontModuleRelations = {
  asudd: 'asudd',
  digital_twin: 'digitalTwin',
  dispatch_control: 'emergency',
  efficiency_control: 'itsControl',
  gis: 'gis',
  ksodd: 'ksodd',
  mciud: 'info',
  modeling: 'modeling',
  parking_space: 'parking',
  permissions: 'licenses',
  public_transport: 'publicTransport',
  road_consulting: 'tsodd',
  system: 'system',
  road_works: 'roadWorks',
  traffic_violations: 'pdd',
  simlog: 'logisticModeling'
}

export const pathToServerModuleRelations = {
  modeling: 'modeling',
  asudd: 'asudd_v2',
  emergency: 'dispatch_control',
  'its-control': 'efficiency_control',
  gis: 'gis',
  info: 'mciud',
  parking: 'parking_space',
  licenses: 'permissions',
  publicTransport: 'public_transport',
  road_works: 'road_works',
  'tsodd-secondary': 'road_consulting',
  scenario: 'scenario_plans',
  tsodd: 'tsodd',
  notifications: 'task_notification',
  transport_situation: 'transport_situation',
  pdd: 'traffic_violations',
  ksodd: 'ksodd',
  'digital-twin': 'digital_twin',
  digitalTwin: 'digital_twin',
  mrir: 'mrir'
}
