export const structure = {
  tree: [],
  map: {},
  activeLayers: [],
  fields: {},
  isSelectDisabled: false,
  layerNames: {},
  objectsCount: {}
}

export const nonClearableFields = ['activeLayers', 'map']
