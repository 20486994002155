const showConsoles = false // toggle here

export const eventNames = {
  // Tools
  ADD_RADIUS_CENTER_POINT: 'add-radius-center-point',
  MAP_LINE_LENGTH_UPDATE: 'map-line-length-update',
  DRAW_PANEL_CLEAR_CLICK: 'draw-panel-clear-click',
  STYLES_CONFIG_UPDATED_FROM_SERVER: 'styles-config-updated-from-server',
  MAP_COORDS_UPDATE: 'map-coords-update',
  MAP_RESIZE: 'map-resize',
  SET_RADIUS_FEATURES: 'set-radius-features',
  DRAW_FIRE_APPLY_MODE: 'draw-fire-apply-mode',

  // Layers
  MAP_CHANGE_PROPERTY: 'map-change-property',
  LAYER_ADDED: 'layer-added',
  LAYER_STYLE_ADDED: 'layer-style-added',
  MAP_UPDATE_FILTER: 'map-update-filter',
  MAP_LAYER_UPDATED: 'map-layer-updated',
  MAP_DATA_UPDATED: 'map-data-updated',
  MAP_UPDATE_LAYER: 'map-update-layer',
  MAP_SET_FILTER: 'map-set-filter',
  TOGGLE_EXTRA_LAYER: 'toggle-extra-layer',
  RESET_IMAGE_LAYER: 'reset-image-layer',
  DELETE_IMAGE: 'delete-image',

  // Common events
  SAVE_BUTTON_CLICKED: 'save-button-clicked',
  REQUEST_FOR_UPDATE: 'request-for-update',
  FIELDS_IS_OUTDATED: 'fields-is-outdated',
  MAP_OBJECT_CLICK: 'map-object-click',
  MAP_CLOSE_OBJECT: 'map-close-object',
  MAP_GEOM_CREATED: 'map-geom-created',
  MAP_GEOM_CHANGED: 'map-geom-changed',
  MAP_FLY_TO_GEOM: 'map-fly-to-geom',
  MAP_OBJECT_LIST: 'map-object-list',
  CLEAR_ALL_DRAW: 'clear-all-draw',
  MAP_SHOW_POPUP: 'map-show-popup',
  UPDATE_FILTERS: 'update-filters',
  START_MEASURE: 'start-measure',
  RESET_FILTERS: 'reset-filters',
  CITY_UPDATED: 'city-updated',
  CONTROLLER_READY: 'controller-ready',
  MODULE_READY: 'module-ready',
  SAVE_COORDS: 'save-coords',
  CHANGE_DRAW_MODE: 'change-draw-mode',
  ADD_TO_HISTORY: 'add-to-history',
  CLEAR_HISTORY: 'clear-history',
  CHANGE_PATH: 'change-path',

  // System
  TOGGLE_EXPORT_LOADING: 'toggle-export-loading',
  WS_IMPORT_MESSAGE: 'ws-import-message',
  MAP_DATA_LOADING: 'map-data-loading',
  CHANGE_BASELAYER: 'change-baselayer',
  TOGGLE_EXPLORER: 'toggle-explorer',
  TOGGLE_LOADING: 'toggle-loading',
  UPDATE_MAP_CONFIG: 'update-map-config',
  LICENSE_ERROR: 'license-error',
  CHANGE_LOCALE: 'change-locale',
  CHANGE_THEME: 'change-theme',
  SELECT_OPEN: 'select-open',
  UPDATE_LIST: 'update-list',
  WS_MESSAGE: 'ws-message',
  WS_USERS_ACTIVITY_MESSAGE: 'ws-users-activity-message',
  TREE_CLICK: 'tree-click',
  WS_INITED: 'ws-inited',
  WS_USERS_ACTIVITY_INITED: 'ws-users-activity-inited',

  // GIS
  GIS_EXTERMINATE_LAYER: 'gis-exterminate-layer',
  GIS_TABLES_UPDATED: 'gis-tables-updated',

  // KSODD
  KSODD_UPDATE_TIMELINE_FILTER: 'ksodd-update-timeline-filter',
  KSODD_TIMELINE_UPDATED: 'ksodd-timeline-updated',

  // ASUDD
  ASUDD_SET_SELECTED_NODE: 'asudd-set-selected-node',

  // MODELING
  LOAD_FULL_DATASET: 'load-full-dataset',
  SAVE_PARAMS: 'save-params',
  CANCEL_CHANGES: 'cancel-changes',
  GO_BACK: 'go-back', // ?

  // DIGITAL TWIN
  UPDATE_MODE_CUSTOM_STATE: 'update-mode-custopm-state',
  OBJECT_CLICK_FOR_BINDING: 'object-click-for-binding',
  SIMULATE_TREE_ITEM_CLICK: 'simulate-tree-item-click',
  UPDATE_ROUTE_OPTION_DRAW: 'update-route-option-draw',
  CLOSE_CREATION_CARD: 'close-creation-card',
  UPDATE_DRAW_FEATURE: 'update-draw-feature',
  ACCEPT_POLYGON_GEOM: 'accept-polygon-geom',
  CLEAR_CREATED_ROUTE: 'clear-created-route',
  GO_THROUGH_HISTORY: 'go-through-history',
  OPEN_VERTEX_POPUP: 'open-vertex-popup'
}

class EventBus {
  #events = {}

  once(event, callback) {
    if (!event) console.error("event doesn't exist")

    if (showConsoles) console.log('once', event)

    const wrapperFn = (...params) => {
      callback(...params)
      this.off(event, wrapperFn)
    }

    this.#addListener(event, wrapperFn, true)
  }

  on(event, callback) {
    if (!event) console.error("event doesn't exist")
    this.#addListener(event, callback, false)

    if (showConsoles) {
      console.log('ON', event, `evts: ${this.#events[event]?.length || 0}`)
    }
  }

  off(event, callback) {
    this.#events[event] = this.#events[event]?.filter(
      event => event.callback !== callback
    )

    if (showConsoles) {
      console.log('OFF', event, `evts: ${this.#events[event]?.length || 0}`)
    }
  }

  emit(event, ...params) {
    if (!event) console.error("event doesn't exist")

    if (showConsoles) console.log('emit', event, ...params)

    if (!this.#events[event]) {
      return
    }

    this.#events[event].forEach(({ callback }) => {
      callback(...params)
    })
  }

  #addListener(event, callback, once) {
    if (!this.#events[event]) {
      this.#events[event] = []
    }

    this.#events[event].push({
      callback,
      once
    })
  }
}

export default new EventBus()
