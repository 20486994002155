const DEV_MODE = process.env.NODE_ENV === 'development'
const ENV_HOST = process.env.VUE_APP_HOST
const ENV_PROJECT = process.env.VUE_APP_PROJECT
const ENV_TILESERVER_HOST = process.env.VUE_APP_TILESERVER_HOST
const VUE_APP_TILESERVER_MAP_HOST = process.env.VUE_APP_TILESERVER_MAP_HOST
const { protocol, host } = window.location

const URLS = {
  test: 'test.ritm3.ru',
  demo: 'demo.ritm3.ru',
  asudd: 'asudd.ritm3.ru',
  new: 'new.ritm3.ru',
  test2: 'test2.ritm3.ru',
  urfu: 'test2.ritm3.ru',
  eputs: 'test2.ritm3.ru',
  avtodor: '10.100.45.3',
  test10: '10.10.10.24',
  eputsiso: 'eputs-so.ritm3.ru',
  surgut: 'surgut.ritm3.ru',
  cheboksary: 'ritm.cheb-its.ru',
  vladimir: '84.53.246.47:10980',
  vladivostok: 'test2.ritm3.ru',
  habarovsk_gis: 'test2.ritm3.ru',
  habarovsk_tpim: 'test2.ritm3.ru',
  habarovsk_akadem: 'test2.ritm3.ru',
  uunit: 'test2.ritm3.ru',
  prod: '/'
}

const WS_PROTOCOLS = {
  'http:': 'ws:',
  'https:': 'wss:'
}

//  ----- * ----- * ----- * ----- * ----- * ----- * ----- * ----- * -----  //

let HOST

if (!DEV_MODE) {
  HOST = URLS.prod
} else {
  HOST = URLS[ENV_PROJECT]
}

//  ----- * ----- * ----- * ----- * ----- * ----- * ----- * ----- * -----  //

// const DEV_PROTOCOL = 'http://'
const DEV_PROTOCOL = 'https://'

const URL = DEV_MODE ? `${DEV_PROTOCOL}${HOST}` : URLS.prod
// const ABS_URL = DEV_MODE ? `${DEV_PROTOCOL}${HOST}` : `${protocol}//${ENV_HOST}`
const ABS_URL = DEV_MODE ? `${DEV_PROTOCOL}${HOST}` : `${protocol}//${host}`
const ABS_TILESERVER_URL = DEV_MODE
  ? `${DEV_PROTOCOL}${HOST}`
  : !!ENV_TILESERVER_HOST &&
    ENV_TILESERVER_HOST !== ''
  ? `${protocol}//${ENV_TILESERVER_HOST}`
  : `${protocol}//${host}`
const TILESERVER_MAP_HOST = VUE_APP_TILESERVER_MAP_HOST

//  ----- * ----- * ----- * ----- * ws * ----- * ----- * ----- * -----  //

const WSP = DEV_MODE ? WS_PROTOCOLS['https:'] : WS_PROTOCOLS[protocol]
// const WSH = DEV_MODE ? HOST : ENV_HOST
const WSH = DEV_MODE ? HOST : host

const WS_URL = `${WSP}//${WSH}/ws`
const DEFAULT_WS_URL = `${WSP}//${WSH}`
const NOTIFY_WS_URL = `${DEFAULT_WS_URL}/task_notification/ws`
const USERS_ACTIVITY_WS_URL = `${DEFAULT_WS_URL}/users_activity/ws`
// eslint-disable-next-line vue/max-len
const NOTIFY_IMPORT_WS_URL = `${DEFAULT_WS_URL}/task_notification/modeling/progress_bar_ws`

//  ----- * ----- * ----- * ----- * ----- * ----- * ----- * ----- * -----  //

const MAIN_TITLE = 'RITM³'

export {
  USERS_ACTIVITY_WS_URL,
  NOTIFY_IMPORT_WS_URL,
  TILESERVER_MAP_HOST,
  ABS_TILESERVER_URL,
  DEFAULT_WS_URL,
  NOTIFY_WS_URL,
  MAIN_TITLE,
  ABS_URL,
  WS_URL,
  URL
}
