import { updateLSSystemConfig } from '@/modules/user/helpers/helpers'
import $store from 'STORE'

export const toggleBodyTheme = async newTheme => {
  const theme = getTheme()

  if (newTheme) {
    await updateLSSystemConfig('theme', newTheme)
  }

  document.body.classList.remove(getTheme(theme))
  document.body.classList.add(getTheme(newTheme || theme))
}

export const getTheme = value => {
  const theme = value || $store.getters.getTheme || 'system'

  if (theme === 'system') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  }

  return theme
}
