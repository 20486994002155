<template>
  <div class="r-textarea">
    <r-text
      v-if="label"
      class="r-textarea__label"
      color-type="subhead"
    >
      {{ label }}
    </r-text>
    <div class="r-textarea__body">
      <r-icon
        class="r-textarea__left"
        :name="iconName"
        :color-type="iconColor"
      />
      <!-- ref="textareaRef" -->
      <textarea
        ref="textarea"
        class="r-textarea__textarea"
        :class="inputClass"
        :style="getStyle()"
        name="textarea"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :value="value"
        :rows="rows"
        :disabled="disabled"
        @input="input"
        @keyup.enter="enter"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />
      <button
        v-if="clearable && value"
        class="r-textarea__clear"
        @click="clear"
      >
        <svg
          v-svg
          symbol="clear-textarea"
          class="r-textarea__svg"
          role="presentation"
        />
      </button>
      <r-text
        class="r-textarea__length"
        type="caption"
      >
        {{ value ? value.length : 0 }}/{{ maxlength }}
      </r-text>

      <div
        v-if="validError && validMessage"
        class="r-textarea__msg"
        :style="{ top: `${textareaHeight}px` }"
      >
        <r-text
          color-type="danger"
          type="caption"
        >
          {{ validMessage }}
        </r-text>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onEnterPress: {
      type: Function,
      default: () => {}
    },
    value: {
      type: [String, Number],
      default: ' '
    },
    placeholder: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    rows: {
      type: Number,
      description: 'Set textarea height',
      default: 3
    },
    resize: {
      type: String,
      description: 'Could be none, both, horizontal, vertical, inherit',
      default: 'none'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    },
    mini: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 250
    },
    validators: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      validError: false,
      validMessage: '',
      textareaHeight: 0
    }
  },
  computed: {
    inputClass() {
      const { disabled, iconName, clearable, value, mini, error, validError } =
        this

      return [
        { 'r-textarea_disabled': disabled },
        { 'r-textarea_iconic-left': iconName },
        { 'r-textarea_iconic-right': clearable },
        { 'r-textarea__mini': mini },
        { 'r-textarea__no-model': value === ' ' },
        { 'r-textarea__error': error || validError }
      ]
    }
  },
  mounted() {
    this.textareaHeight = this.$refs.textarea?.getBoundingClientRect()?.height
  },
  methods: {
    enter($event) {
      this.$emit('enter', $event)
    },
    input($event) {
      const value = $event.target.value

      this.$emit(
        'input',
        this.type === 'number' ? Number(value) : String(value)
      )

      if (!value) {
        this.validError = false
        this.validMessage = ''

        return
      }

      const failedValidator = this.getFailedValidator(value)

      this.validError = !!failedValidator
      this.validMessage = failedValidator?.message
    },
    clear() {
      this.$emit('input', '')
    },
    getStyle() {
      const styles = {}
      styles.resize = this.resize

      return styles
    },
    getFailedValidator(value) {
      if (!this.validators?.length) return null

      return this.validators.find(v => !v.validator(value, v.param))
    }
  }
}
</script>

<style lang="scss" scoped>
.r-textarea {
  display: grid;
  grid-gap: 0.25rem;

  &__mini {
    padding: 0 0.25rem !important;
    text-align: center;
  }

  &__length {
    min-height: 100%;
    display: flex;
    align-items: flex-end;
    position: absolute;
    right: 12px;
    bottom: 8px;
  }

  &__clear {
    position: absolute;
    right: 8px;
    top: 8px;
    background: transparent;
    border: none;
    z-index: 1;
    opacity: 0;
    height: 22px;
  }

  &__password {
    position: absolute;
    right: 8px;
    background: transparent;
    border: none;
    z-index: 1;
    height: 22px;
    cursor: pointer;
  }

  &__left {
    position: absolute;
    left: 8px;
    top: 8px;
    display: inline-block;
  }

  &__body {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  &__label {
    height: 24px;
  }

  &__no-model {
    pointer-events: none;
  }

  &__textarea {
    height: 100%;
    border-radius: $border-radius;
    padding: 8px 12px;
    transition: 0.16s;
    width: 100%;
    color: $text-primary;
    background: $field-bg;
    border: 1px solid $field-border;

    &::placeholder {
      color: $field-placeholder;
    }

    &:focus {
      @include border($field-active-border);
    }

    &:focus ~ .r-textarea__clear {
      opacity: 1;
      cursor: pointer;
    }
  }

  &_disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &_iconic-left {
    padding-left: 36px;
  }

  &_iconic-right {
    padding-right: 32px;
  }

  &__error {
    border-color: $accent-danger !important;
  }

  &__svg {
    width: 22px;
    height: 22px;
    fill: $icons-high-contrast;

    &:hover {
      fill: $accent-primary;
    }
  }

  &__msg {
    position: absolute;
    top: 36px;
  }
}
</style>
