export const structure = {
  tree: [],
  map: {},
  layerNames: {},
  fullDataSetLoaded: {},
  // mainGisFields: {},
  layerTypes: {},
  fields: {},
  selectedModelId: null,
  activeLayers: [],
  isSelectDisabled: false,
  scenarioStatuses: null,
  objectsCount: {}
  // currentModelId: null,
}

export const nonClearableFields = ['map', 'fullDataSetLoaded', 'activeLayers']
