<template>
  <li
    :id="id"
    :class="[
      'r-select-option',
      focused ? 'focus' : '',
      disabled ? 'disabled' : '',
      multiple ? 'multiple' : '',
      isSelected ? 'active' : '',
      hasNoTextData ? 'has-no-text-data' : ''
    ]"
    @click.stop="$emit('click', $event)"
  >
    <slot />
    <render-option
      v-if="!slots.default && html"
      :dom="slot"
    />
    <div class="r-select-option__item-wrapper">
      <r-checkbox
        v-if="multiple && !html"
        :label="title"
        :checked="isSelected"
        :white-space="mode === 'wrap' ? 'unset' : 'nowrap'"
        @click="$emit('click', $event)"
      />
      <r-text
        v-else-if="!html"
        :title="title"
      >
        {{ title !== undefined && title !== null ? title : '—' }}
      </r-text>
    </div>
  </li>
</template>

<script setup>
import { defineAsyncComponent as DAC, computed, useSlots } from 'vue'

const slots = useSlots()

const RenderOption = DAC(() => import('./r-select/components/render-option'))

// eslint-disable-next-line no-undef
const $emit = defineEmits(['click'])

// eslint-disable-next-line no-undef
const props = defineProps({
  id: {
    type: [String, Number],
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  html: {
    type: Array,
    default: null
  },
  active: {
    type: [Array, String, Number],
    default: null
  },
  mode: {
    // ellipsis, tooltip, wrap
    type: String,
    default: 'ellipsis'
  },
  multiple: {
    type: Boolean,
    default: false
  },
  focused: {
    type: Boolean,
    default: false
  },
  hasNoTextData: {
    type: Boolean,
    default: false
  }
})

const slot = computed(() => {
  return props.html ? props.html[0].componentOptions.children : null
})
const isSelected = computed(() => {
  const { id, multiple, active, hasNoTextData } = props

  if (!multiple) {
    return active === id
  } else if (multiple && hasNoTextData) {
    return active[0] === id
  } else {
    return !!active?.some(e => e === id)
  }
})
</script>

<style lang="scss" scoped>
.r-select-option {
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  align-items: center;
  grid-gap: 0.5rem;
  overflow: hidden;
  border-radius: $border-radius;

  .r-text {
    white-space: nowrap;
  }

  .r-tooltip__wrapper,
  .r-select-option__item-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.multiple {
    padding-left: 12px;

    &.active {
      background: unset;
    }

    &:hover {
      background: $field-hover-bg;
    }
  }

  &.multiple.has-no-text-data {
    &.active {
      background: $accent-primary-1;
    }
  }

  &.focus {
    background: $field-hover-bg !important;
  }

  &:hover {
    background: $field-hover-bg;
  }

  &.active {
    background: $accent-primary-1;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.wrap .r-select-option {
  .r-text {
    white-space: unset;
  }
}
</style>
