<template>
  <div
    v-disabled="disabled"
    class="r-range-slider"
  >
    <r-text
      v-if="title"
      color-type="subhead"
    >
      {{ title }}
    </r-text>
    <div class="r-range-slider__wrapper">
      <div class="r-range-slider__slider">
        <div class="r-range-slider__track">
          <span
            class="r-range-slider__inactive-part"
            :style="getTrackWidth(1)"
          />
          <span
            class="r-range-slider__active-part"
            :style="getTrackWidth(2)"
          />
          <span
            class="r-range-slider__inactive-part"
            :style="getTrackWidth(3)"
          />
          <ul class="r-range-slider__marks">
            <li
              v-for="(name, key) in marks"
              :key="key"
              :style="{ left: getScalePoint(key) }"
              class="r-range-slider__mark"
              :class="{ inside: marksInside }"
            >
              <span />
              <r-text
                :class="{ vertical }"
                color-type="secondary"
              >
                {{ name }}
              </r-text>
            </li>
          </ul>
        </div>
        <template v-if="!simple">
          <input
            v-model="firstInput"
            type="range"
            :min="min"
            :disabled="disabled"
            :max="max"
            :step="step"
            @input="updateInput($event, 'first')"
          />
          <input
            v-model="secondInput"
            type="range"
            :min="min"
            :disabled="disabled"
            :max="max"
            :step="step"
            @input="updateInput($event, 'second')"
          />
        </template>
        <template v-if="simple">
          <input
            v-model="simpleInput"
            type="range"
            :min="min"
            :max="max"
            :step="step"
            @input="updateInput($event, 'simple')"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
// Imports
import { throttle } from 'HELPERS'
import { ref, watch, toRefs } from 'vue'

// Service constants (Compositions & EventBus)

// Components

// Emits & Props
// eslint-disable-next-line no-undef
const $emit = defineEmits('input')
// eslint-disable-next-line no-undef
const props = defineProps({
  title: {
    type: String,
    default: null
  },
  value: {
    type: [Array, Number],
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 9
  },
  step: {
    type: Number,
    default: null
  },
  marks: {
    type: Object,
    default: null
  },
  showStops: {
    type: Boolean,
    default: false
  },
  vertical: {
    type: Boolean,
    default: false
  },
  simple: {
    type: Boolean,
    default: false
  },
  minmaxRelation: {
    type: Number,
    default: null
  },
  marksInside: {
    type: Boolean,
    default: false
  }
})

// Refs & Reactive
const { value: input } = toRefs(props)
const firstInput = ref(input.value[0])
const secondInput = ref(input.value[1])
const simpleInput = ref(input.value)

// Computed

// Methods
const updateInput = function (e, pos) {
  const val = +e.target.value

  switch (pos) {
    case 'first':
      if (props.minmaxRelation) {
        if (val > props.max - props.minmaxRelation) {
          firstInput.value = val - props.minmaxRelation
        } else {
          firstInput.value = val
          secondInput.value = val + props.minmaxRelation
        }
      } else {
        firstInput.value = val
      }

      break
    case 'second':
      if (props.minmaxRelation) {
        if (val < props.min + props.minmaxRelation) {
          secondInput.value = val + props.minmaxRelation
        } else {
          secondInput.value = val
          firstInput.value = val - props.minmaxRelation
        }
      } else {
        secondInput.value = val
      }

      break
    case 'simple':
      simpleInput.value = val

      return throttle($emit('input', val), 250)
  }

  const minVal = Math.min(firstInput.value, secondInput.value)
  const maxVal = Math.max(firstInput.value, secondInput.value)

  const result = [minVal, maxVal]

  return throttle($emit('input', result), 250)
}

const getTrackWidth = zone => {
  const { min, max, simple } = props

  const allWidth = max - min

  const minVal = simple ? min : Math.min(firstInput.value, secondInput.value)
  const maxVal = simple ? min : Math.max(firstInput.value, secondInput.value)

  let part

  switch (zone) {
    case 1:
      part = ((minVal - min) / allWidth) * 100
      break
    case 2:
      part = ((maxVal - minVal) / allWidth) * 100
      break
    case 3:
      part = ((max - maxVal) / allWidth) * 100
      break
  }

  return { 'flex-basis': `${part || 0}%` }
}

const getScalePoint = value => {
  const fromStart = value - props.min
  const procent = (fromStart * 100) / (props.max - props.min)
  const corrector = fromStart / props.max
  // const corrector = 0

  return `calc(${procent}% - ${corrector}px)`
}

// Hooks

// Watch
watch(props, val => {
  firstInput.value = val.value[0]
  secondInput.value = val.value[1]
  simpleInput.value = val.value
})
</script>

<style lang="scss" scoped>
.r-range-slider {
  display: grid;
  grid-gap: 0.25rem;
  width: 100%;
  height: 30px;

  &__wrapper {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    align-items: center;
  }

  &__slider {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__track {
    height: 2px;
    position: absolute;
    margin: auto;
    top: 5px;
    bottom: 30px;
    width: calc(100% - 20px);
    left: 10px;
    border-radius: 5px;
    display: flex;
  }

  &__inactive-part {
    background-color: $dividers-low-contrast;
    flex: 0 0 0%;
    height: 100%;
  }

  &__active-part {
    background-color: $accent-primary;
    flex: 0 0 0%;
    height: 100%;
    z-index: 1;
  }

  &__marks {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;

    span {
      display: block;
      width: 1px;
      height: 6px;
      z-index: 1;
      background-color: $icons-low-contrast;
    }

    .r-text {
      white-space: nowrap;
      margin-top: 0.5rem;

      &.vertical {
        writing-mode: vertical-rl;
      }
    }
  }

  &__mark {
    position: absolute;
    top: -2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);

    &.inside {
      &:first-child {
        justify-content: flex-start;
        align-items: flex-start;
        transform: translateX(0);
      }
      &:last-child {
        justify-content: flex-end;
        align-items: flex-end;
        transform: translateX(-100%);
      }
    }
  }

  @mixin track {
    height: 5px;
    z-index: 1;
  }

  @mixin thumb {
    cursor: grab;
    height: 16px;
    width: 8px;
    background-color: $accent-primary;
    margin-top: -5px;
    pointer-events: auto;
    border-radius: 4px;
    z-index: 2;
  }

  input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    position: absolute;
    margin: auto;
    left: 10px;
    top: 5px;
    bottom: 30px;
    width: calc(100% - 20px);
    background-color: transparent;
    pointer-events: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    @include track;
  }

  input[type='range']::-moz-range-track {
    -moz-appearance: none;
    @include track;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    @include thumb;
  }

  input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    @include thumb;
  }

  input[type='range']:active::-webkit-slider-thumb {
    cursor: grabbing;
  }

  input[type='range']:active::-moz-range-thumb {
    cursor: grabbing;
  }
}
</style>
