import { systemSettingsDefault } from '@/config/system-settings'
import { setLFconfigToStore, updateLFConfig } from 'HELPERS'

export const updateLSSystemConfig = async (key, value) => {
  await updateLFConfig({
    key,
    value,
    configName: 'systemConfig',
    defaultConfig: systemSettingsDefault
  })
}

export const setSystemConfig = async () => {
  await setLFconfigToStore({
    configName: 'systemConfig',
    defaultConfig: systemSettingsDefault,
    setter: 'SYSTEM_SET'
  })
}
