export const getDisplayedModel = ({
  model,
  filterText,
  displayedAttributes
}) => {
  if (!model || (!displayedAttributes && !filterText))
    return { displayedModel: model, hasHiddenAttributes: false }

  let hasHiddenAttributes = false

  const displayedModel = model.filter(item => {
    let filterRes

    if (filterText) {
      const text = filterText.trim().toLowerCase()

      filterRes = (item.alias?.ru || item.title).toLowerCase().includes(text)
    } else {
      filterRes = true
    }

    if (!displayedAttributes) return filterRes

    const groupId =
      item.group === null || item.group === undefined ? 'groupless' : item.group

    if (!Array.isArray(displayedAttributes[groupId])) return filterRes

    const isDisplayed = displayedAttributes[groupId]?.includes(item.model)

    if (!isDisplayed && !hasHiddenAttributes) hasHiddenAttributes = true

    return filterRes && isDisplayed
  })

  return { displayedModel, hasHiddenAttributes }
}
