import vue from 'vue'
import { structure, nonClearableFields } from './structure'
import { cloneDeep } from 'HELPERS'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getPDDCity: state => state.city,
    getPDDViolationId: state => state.violationId,
    getPDDCameraId: state => state.cameraId,
    getPDDFields: state => state.fields,
    getPDDStatuses: state => state.statuses
  },
  mutations: {
    PDD_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    PDD_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        state[field] = value
      }
    }
  }
}
