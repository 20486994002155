<template>
  <portal to="main-portal">
    <transition
      name="r-modal"
      @before-enter="beforeEnter"
    >
      <div
        v-if="show"
        class="r-modal__wrapper"
        :style="{ zIndex }"
        @mousedown.stop="handleMouseDown"
        @mouseup.stop="handleMouseUp"
      >
        <!-- @mousedown.stop="closeModalByOverlay" -->
        <div
          class="r-modal__overlay"
          :class="{ 'scroll-in-modal': scrollInsideModal }"
        >
          <div
            ref="content"
            class="r-modal__content"
            :class="{ 'keep-this-class-opened': ddParent }"
            :style="style"
            role="dialog"
          >
            <r-button
              class="r-modal__close-button"
              icon="close-delete"
              mini
              simple
              @click="closeModal"
            />
            <r-title
              v-if="title"
              class="r-modal__title"
            >
              {{ title }}
            </r-title>
            <div
              class="r-modal__body"
              :class="[{ rounded }, bodyClass]"
            >
              <slot />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script setup>
import i18n from '@/extensions/i18n'

import { ref, onBeforeUnmount, nextTick, watch, computed } from 'vue'
import vue from 'vue'

// eslint-disable-next-line no-undef
const $emit = defineEmits(['close'])

// eslint-disable-next-line no-undef
const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  bodyClass: {
    type: String,
    default: ''
  },
  rounded: {
    type: Boolean,
    default: false
  },
  ddParent: {
    type: Boolean,
    default: false
  },
  askBeforeClose: {
    type: Boolean,
    default: false
  },
  closeByOverlay: {
    type: Boolean,
    default: true
  },
  closeByEsc: {
    type: Boolean,
    default: true
  },
  maxWidth: {
    type: Number,
    default: 640
  },
  marginTop: {
    type: Number,
    default: 64
  },
  zIndex: {
    type: Number,
    default: 1000
  },
  scrollInsideModal: {
    type: Boolean,
    default: false
  }
})

const show = ref(props.show)
const content = ref(null)
const isOverlayMouseDown = ref(false)
// eslint-disable-next-line vue/max-len
const style = computed(
  () => `max-width: ${props.maxWidth}px; margin: ${props.marginTop}px auto;`
)

const closeModal = async () => {
  if (props.askBeforeClose) {
    if (
      await vue.$dialog({
        title: i18n.t('close-this-window'),
        message: i18n.t('unsaved-changes-will-be-lost')
      })
    ) {
      $emit('close')
    }
  } else $emit('close')
}

const handleMouseDown = e => {
  if (
    props.closeByOverlay &&
    !isOverlayMouseDown.value &&
    e.composedPath()[0].className === 'r-modal__overlay'
  ) {
    isOverlayMouseDown.value = true
  }
}

const handleMouseUp = e => {
  if (
    props.closeByOverlay &&
    isOverlayMouseDown.value &&
    e.composedPath()?.[0]?.className === 'r-modal__overlay'
  ) {
    closeModal()
  }

  isOverlayMouseDown.value = false
}

const escCloseModal = e => {
  e.stopPropagation()
  e.preventDefault()

  if (props.show && e.code === 'Escape' && props.closeByEsc) {
    closeModal()
  }
}

const beforeEnter = () => {
  if ('click' in content.value) {
    nextTick(() => content.value?.click())
  }
}

watch(
  () => props.show,
  isShow => {
    show.value = isShow

    if (isShow && props.closeByEsc) {
      window.addEventListener('keyup', escCloseModal, true)
    } else {
      window.removeEventListener('keyup', escCloseModal, true)
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  window.removeEventListener('keyup', escCloseModal, true)
})
</script>

<style lang="scss" scoped>
.r-modal {
  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $modal-overlay;
    opacity: 1;
    transition: all 0.15s ease-in-out;
    z-index: 1000;
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__content {
    width: 100%;
    padding: 1.5rem 1rem;
    border-radius: $border-radius;
    background-color: $modal-bg;
    display: grid;
    grid-gap: 1rem;
    position: relative;
    box-shadow: $box-shadow-xl;
  }

  &__title {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;

    &:empty {
      display: none;
    }
  }

  &__body {
    overflow: hidden;
    display: grid;
    /* padding-top: 0.25rem; */

    &.rounded {
      border-radius: $border-radius;
    }
  }

  &__close-button {
    right: 0.2rem;
    top: 0.2rem;
    position: absolute;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.15s ease-in-out;
    position: absolute;
  }
}

.scroll-in-modal {
  display: grid;
  align-items: start;

  &.r-modal__overlay {
    overflow: hidden;
  }
  .r-modal__content {
    max-height: 90vh;
    grid-template-rows: auto 1fr;
  }
}
</style>
