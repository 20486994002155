import COLORS from 'COLORS'

export const structure = {
  city: null,
  map: {},
  stopId: null,
  routeId: null,
  vehicleId: null,
  activeRoutesId: [],
  activeGroupsId: [],
  currentRoute: null,
  currentRoutes: [],
  currentPointCoordinates: [],
  routeColor: COLORS.mapColors.default,
  fields: {},
  filters: {
    stops: [],
    vehicles: []
  },
  boardsId: null,
  routeStopsIds: null,
  vehicleTypes: null,
  layerNames: {},
  reportConfigs: {}
}

export const nonClearableFields = ['city', 'map']
