import { ref } from 'vue'

export function useLoading() {
  const loading = ref(false)

  const toggleLoading = value => {
    loading.value = value ?? !loading.value
  }

  return { loading, toggleLoading }
}
