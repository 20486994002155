export default {
  isRouteAvailable: state => routeName => state.availableRoutes.has(routeName),
  getMapByModule: state => module => state[module]?.map,
  getGeomEditorState: state => state.isDrawing,
  isAuthorized: state => state.authorized,
  getDownloads: state => state.downloads,
  getAbbrState: state => state.abbr,
  getWsNotifyState: state => state.hideWsNotifications,
  getLocale: state => state.locale,
  getTheme: state => state.theme
}
